<template>
  <div>
    <section class="section">
      <div class="section-center">
        <div class="columntitle">
          <span> 新闻动态 </span>
          <span> Journalism </span>
          <span>
            <img src="../../assets/image/enterpriseNews/lineimg.png" alt="" />
          </span>
        </div>
        <div class="subtitle">
          <p>有为信通乔迁之喜暨华为智能协作体验中心揭幕仪式成功举办！</p>
          <p>发表时间:2021-05-27 17:03</p>
        </div>
        <div class="video-box">
          <video class="video" controls="controls" src="http://25694154.s21v.faiusr.com/58/1/ABUIABA6GAAg8KPChQYowNHZuwM.mp4"></video>
        </div>
        <div class="describe">
          <div class="bg">
            <span>
              2021年5月25日，有为信通乔迁之喜暨华为智能协作体验中心揭幕仪式在公司新址隆重举行，华为领导和公司领导共同为公司乔迁之喜剪彩，并宣布公司正式入驻新办公区（北京市丰台科技园盈坤世纪D座8层），华为领导还为公司的“华为北京机器视觉好望学堂能力中心”举行了授牌仪式，现场掌声热烈。
            </span>
          </div>
        </div>
        <div class="imgbox">
          <div class="imgbox-center">
            <img
              src="../../assets/image/enterpriseNews/Housewarming/img1.png"
              alt=""
            />
            <img
              src="../../assets/image/enterpriseNews/Housewarming/img2.png"
              alt=""
            />
          </div>
        </div>
        <div class="describe describe1">
          <div class="bg">
            <span>
              更加舒适优雅的办公区，带给员工更加蓬勃的朝气，也必将带给公司业务上的再一次腾飞！下面小编和您一起探寻新办公区的面貌，一起来看看吧：
            </span>
          </div>
        </div>
        <div class="bg-title">
          <img
            src="../../assets/image/enterpriseNews/Housewarming/bg-title.png"
            alt=""
          />
        </div>
        <div class="bg-subtitle">
          智能协作体验中心（端云协同，攻坚全场景智慧办公生态）
        </div>
        <div class="imgbox">
          <div class="imgbox-center">
            <img
              src="../../assets/image/enterpriseNews/Housewarming/img3.png"
              alt=""
            />
            <img
              src="../../assets/image/enterpriseNews/Housewarming/img4.png"
              alt=""
            />
            <img
              src="../../assets/image/enterpriseNews/Housewarming/img5.png"
              alt=""
            />
            <div class="img-title">一体化协作智真</div>
            <img
              src="../../assets/image/enterpriseNews/Housewarming/img6.png"
              alt=""
            />
          </div>
        </div>
        <div class="textdescribe">
          <span
            >作为展厅的代表型产品，HUAWEI CloudLink RoomPresence
            集成编解码器、摄像机、单双显示屏、数字阵列麦克风、扬声器、一体化结构件，使用Touch平板统一操控，适用于大中小型会议室，多人召开智真会议的应用场景。
            另外，智慧金融、智慧安平、智慧城市、专业智真系列、智慧教育等板块也陈列了多款智能协作产品，以及由各企事业单位合作共赢打造的智能协作生态，必将带动产业链的稳步发展~</span
          >
        </div>
        <div class="bg-title">
          <img
            src="../../assets/image/enterpriseNews/Housewarming/bg-title1.png"
            alt=""
          />
        </div>
        <div class="imgbox">
          <div class="imgbox-center">
            <img
              style="margin: 0"
              src="../../assets/image/enterpriseNews/Housewarming/img7.png"
              alt=""
            />
            <div class="img-title">
              作为标准会议室，磐石这个命名，寓意着坚定，我们的同事心存坚定的信念，去追寻我们的理想，走向远方~
            </div>
          </div>
        </div>

        <div class="bg-title">
          <img
            src="../../assets/image/enterpriseNews/Housewarming/bg-title2.png"
            alt=""
          />
        </div>
        <div class="imgbox">
          <div class="imgbox-center">
            <img
              style="margin: 0"
              src="../../assets/image/enterpriseNews/Housewarming/img8.png"
              alt=""
            />
            <div class="img-title">
              作为正式一点的接待室，瀚海，是取“海纳百川，有容乃大”的寓意，我们诚挚欢迎来自各地的朋友，交流合作，共谋发展！
            </div>
          </div>
        </div>

        <div class="bg-title">
          <img
            src="../../assets/image/enterpriseNews/Housewarming/bg-title3.png"
            alt=""
          />
        </div>
        <div class="imgbox">
          <div class="imgbox-center">
            <img
              style="margin: 0"
              src="../../assets/image/enterpriseNews/Housewarming/img9.png"
              alt=""
            />
            <div class="img-title">
              作为洽谈室，辰星，取“寥若辰星”之意，我们希望，来这里的都是精英，也必将做出英明睿智的决定！
            </div>
          </div>
        </div>

        <div class="bg-title">
          <img
            src="../../assets/image/enterpriseNews/Housewarming/bg-title4.png"
            alt=""
          />
        </div>
        <div class="imgbox">
          <div class="imgbox-center">
            <img
              style="margin: 0"
              src="../../assets/image/enterpriseNews/Housewarming/img10.png"
              alt=""
            />
            <div class="img-title">
              作为茶歇式接待室，灵犀，是取“身无彩凤双飞翼，心有灵犀一点通”的寓意，我们欢迎志同道合的朋友，来这里喝茶聊天，探讨未来。
            </div>
          </div>
        </div>

        <div class="bg-title">
          <img
            src="../../assets/image/enterpriseNews/Housewarming/bg-title5.png"
            alt=""
          />
        </div>
        <div class="imgbox">
          <div class="imgbox-center">
            <img
              style="margin: 0"
              src="../../assets/image/enterpriseNews/Housewarming/img11.png"
              alt=""
            />
            <div class="img-title">
              作为标准会议室，极光这个命名，寓意着有效，有效的沟通，也必将带来较好的办公效率。
            </div>
          </div>
        </div>
        <div class="textdescribe">
          <span
            >新的起点，新的起航，我们团结一心，奋发昂扬，坚持“以客户为中心，以奋斗者为本，以价值为纲”的核心价值观，为客户提供更加优质的产品与技术服务，为员工营造更加光明的发展前景，为公司书写更加华丽的篇章~</span
          >
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {

};
</script>
<style lang="scss" scoped>
.section {
  width: 100%;
  .section-center {
    padding-top: 50px;
    font-size: 18px;
    color: #000;
    max-width: 1466px;
    margin: 0 auto;
    .columntitle {
      display: flex;
      justify-content: space-between;
      line-height: 30px;
      color: #6f36eb;
      text-align: left;
      margin-bottom: 40px;
      span {
        font-size: 30px;
        display: inline-block;
      }
      span:nth-child(2) {
        margin: 0 10px 0 14px;
        font-size: 28px;
        color: #ccc;
      }
      span:nth-child(3) {
        img {
          width: 100%;
        }
        max-width: 1160px;
      }
    }
    .subtitle {
      font-family: IOS9W4;
      font-weight: 400;
      padding: 22px 0 20px 24px;
      text-align: left;
      background-color: #f5f5f5;
      p:nth-child(1) {
        height: 30px;
        font-size: 30px;
        color: #333333;
        margin-bottom: 14px;
      }
      p:nth-child(2) {
        height: 20px;
        font-size: 20px;
        color: #999;
      }
    }
    .video-box {
      padding: 30px 0 58px 0;
      height: 500px;
      .video {
        width: 900px;
        height: 100%;
      }
    }
    .describe {
      border: 1px dashed #aad4ee;
      height: 220px;
      border-radius: 4px;
      padding: 4px;
      .bg {
        border-radius: 4px;
        background-color: #f4effd;
        height: 180px;
        color: #8735e4;
        font-size: 18px;
        padding: 40px 48px 0 48px;
        line-height: 60px;
        text-align: left;
      }
      // background-color: ;
    }
    .textdescribe {
      text-align: left;
      line-height: 60px;
    }
    .describe1 {
      height: 120px;
      .bg {
        height: 80px;
      }
    }
    .imgbox {
      .imgbox-center {
        margin: 64px auto;
        max-width: 900px;
        .img-title {
          height: 20px;
          margin: 22px 0 15px 0;
          color: #333;
        }
        img {
          margin-bottom: 40px;
          width: 100%;
        }
        img:last-child {
          margin: 0;
        }
        img:nth-child(3) {
          margin: 0;
        }
      }
    }
    .bg-title {
      height: 28px;
      margin: 84px 0 60px 0;
      img{
        width: 100%;
      }
    }
    .bg-subtitle {
      height: 20px;
      margin-bottom: 40px;
      color: #333;
    }
  }
}
</style>